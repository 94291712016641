import React, { useEffect } from "react"
import "../scss/HeritagePageText2.scss"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Aos from "aos"
import "aos/dist/aos.css"
import { Helmet } from "react-helmet";
import arrow from './arrow_down.png';

export default function AboutUsPageText2() {
  const data = useStaticQuery(graphql`
    query MyQuery2301 {
      allContentfulTextBlock(filter: { id: { eq: "2ccf816a-ae3a-58ce-9945-2dc6c1c918d1" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            title
          }
        }
      }
    }
  `)

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  if (data.allContentfulTextBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.allContentfulTextBlock.edges[0].node

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 500,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <h2>Inspired by 17th Century Scientist Sir Francis Bacon</h2>
        <h3>A craft defined by the origins of scientific data gathering and experimentation</h3>
      </Helmet>
    <div className="contentFeatureHeritageText2">
      <div className="textContainerHeritageText2">
        <p className="titleHeritageText2">{title}</p>
        {bodyText && (
          <div className="bodyHeritageText2">
            {documentToReactComponents(JSON.parse(bodyText.raw))}
          </div>
        )}
          <div className={'arrowContainerHeritageText2'}>
          <img
            className={'arrowHeritageText2'}
            src={arrow}
            alt="arrow"
            onClick={handleArrowClick} // Attach the click handler here
          />
      </div>
      </div>
    </div>
    </>
  )
}
