import React, { useEffect } from "react";
import "../scss/HeritagePagePic2.scss";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import arrow from './arrow_down.png';

export default function AboutUsPagePic2() {
  const data = useStaticQuery(graphql`
    query MyQuery2501 {
      allContentfulImageBlock(filter: { id: { eq: "f06cb25c-2930-5aad-9a3f-d5d870595e25" } }) {
        edges {
          node {
            image {
              image {
                file {
                  url
                }
              }
            }
            name
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  if (data.allContentfulImageBlock.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <div>
    <div className="contentFeatureHeritagePagePic2">
      <div data-aos="fade-up" className="boardTextContainerHeritagePagePic2 fade-in">
        <div className={"displayBoardContainerHeritagePagePic2"}>
          <div className="displayBoardHeritagePagePic2"></div>
        </div>
      </div>

      <div className={"textContainerHeritagePagePic2"}>
        <p className={"titleHeritagePagePic2"}>
          {data.allContentfulImageBlock.edges[0].node.name}
        </p>
      </div>

      <div className={"imageContainerHeritagePagePic2"}>
        <img
          data-aos="fade-up"
          className={"bigImage"}
          alt={""}
          key={""}
          src={data.allContentfulImageBlock.edges[0].node.image.image.file.url}
        />
      </div>
    </div>
        <div className={'arrowContainerHeritagePic2'}>
        <img
          className={'arrowHeritagePic2'}
          src={arrow}
          alt="arrow"
          onClick={handleArrowClick} // Attach the click handler here
        />
    </div>
    </div>
  );
}
