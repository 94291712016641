import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import "../scss/HeroImageHeritage.scss"

export default function HeroImageHeritage() {
    const data = useStaticQuery(graphql`
    query MyQuery1900 {
      allContentfulHeroImage(filter: { id: { eq: "58998321-babc-55fe-9d40-55082fa262dc" } }) {
        edges {
          node {
            heroImage {
              altText
              image {
                file {
                  url
                }
                id
              }
              name
            }
          }
        }
      }
    }
  `)

    return (
        <div className="heroImageHeritageContainer">
            <img
              className={"heroImageHeritage"}
              alt="TFK Heritage Photo"
              key={``} 
              src={data.allContentfulHeroImage.edges[0].node.heroImage.image.file.url}
            />
        </div>
    )
}
