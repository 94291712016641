import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import "../scss/HeritagePagePic1.scss";
import arrow from './arrow_down.png';

export default function AboutUsPagePic1() {
  const data = useStaticQuery(graphql`
    query MyQuery2200 {
      allContentfulFlexBoxStyle(filter: { title: { eq: "Empirical Mindset" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  const backgroundImageUrl = data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url;

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <div>
    <div className={isMobileView ? "normalImageContainerHeritagePagePic1" : "parallaxContainerHeritagePagePic1"}>
      <p className="titleHeritagePagePic1">Empirical Mindset</p>
      {isMobileView ? (
        <img src={backgroundImageUrl} alt={data.allContentfulFlexBoxStyle.edges[0].node.image.altText} />
      ) : (
        <div className="parallaxHeritagePagePic1" style={{ backgroundImage: `url(${backgroundImageUrl})` }} />
      )}
    </div>
        <div className={'arrowContainerHeritagePic1'}>
            <img
              className={'arrowHeritagePic1'}
              src={arrow}
              alt="arrow"
              onClick={handleArrowClick} // Attach the click handler here
            />
        </div>
    </div>
  );
}


//https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_parallax















// import React, { useEffect, useState } from "react";
// import "../scss/AboutUsPagePic1.scss";
// import { graphql, useStaticQuery } from "gatsby";
// import Aos from "aos";
// import "aos/dist/aos.css";

// export default function AboutUsPagePic1() {
//   const data = useStaticQuery(graphql`
//     query MyQuery22 {
//       allContentfulFlexBoxStyle(filter: { title: { eq: "My Heritage" } }) {
//         edges {
//           node {
//             bodyText {
//               raw
//             }
//             callToAction
//             image {
//               altText
//               image {
//                 file {
//                   url
//                 }
//               }
//             }
//             subTitle
//             title
//           }
//         }
//       }
//     }
//   `);

//   useEffect(() => {
//     Aos.init({ duration: 1000, offset: -60 });
//   }, []);

//   if (data.allContentfulFlexBoxStyle.edges.length === 0) {
//     return null; // Handle the case when the desired node is not found
//   }

//   return (
//     <div className="contentFeature7">
//       {/* <div data-aos="fade-up" className="boardTextContainer7 fade-in">
//         <div className={"textContainer7"}>
//           <p className={"title7"}>
//             {data.allContentfulFlexBoxStyle.edges[0].node.title}
//           </p>
//         </div>
//       </div> */}

//       {/* <div className={"displayBarContainer7"}>
//         <div data-aos="fade-up" className="displayBar7" />
//       </div> */}
//       <div className={"imageContainer7"}>
//         <img
//           // data-aos="fade-up"
//           className={"bigImage"}
//           alt={data.allContentfulFlexBoxStyle.edges[0].node.image.altText}
//           key={data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url}
//           src={data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url}
//         />
//       </div>
//     </div>
//   );
// }
