import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Aos from "aos";
import "aos/dist/aos.css";
import "../scss/HeritagePagePic3.scss";
import arrow from './arrow_down.png';

export default function HeritagePagePic3() {
  const data = useStaticQuery(graphql`
    query MyQuery2201 {
      allContentfulFlexBoxStyle(filter: { id: { eq: "a03b4f43-708f-541a-8a5a-164bbbe426d2" } }) {
        edges {
          node {
            bodyText {
              raw
            }
            callToAction
            image {
              altText
              image {
                file {
                  url
                }
              }
            }
            subTitle
            title
          }
        }
      }
    }
  `);

  useEffect(() => {
    Aos.init({ duration: 1000, offset: -60 });
  }, []);

  const backgroundImageUrl = data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url;

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <div>
    <div className={isMobileView ? "normalImageContainerHeritagePagePic3" : "parallaxContainerHeritagePagePic3"}>
      {/* <p className="titleHeritagePagePic3">Empirical Mindset</p> */}
      {isMobileView ? (
        <img src={backgroundImageUrl} alt={data.allContentfulFlexBoxStyle.edges[0].node.image.altText} />
      ) : (
        <div className="parallaxHeritagePagePic3" style={{ backgroundImage: `url(${backgroundImageUrl})` }} />
      )}
    </div>
    <div className={'arrowContainerHeritagePic3'}>
        <img
          className={'arrowHeritagePic3'}
          src={arrow}
          alt="arrow"
          onClick={handleArrowClick} // Attach the click handler here
        />
    </div>
    </div>
  );
}


//https://www.w3schools.com/howto/tryit.asp?filename=tryhow_css_parallax















// import React, { useEffect, useState } from "react";
// import "../scss/AboutUsPagePic3.scss";
// import { graphql, useStaticQuery } from "gatsby";
// import Aos from "aos";
// import "aos/dist/aos.css";

// export default function AboutUsPagePic3() {
//   const data = useStaticQuery(graphql`
//     query MyQuery22 {
//       allContentfulFlexBoxStyle(filter: { title: { eq: "My Heritage" } }) {
//         edges {
//           node {
//             bodyText {
//               raw
//             }
//             callToAction
//             image {
//               altText
//               image {
//                 file {
//                   url
//                 }
//               }
//             }
//             subTitle
//             title
//           }
//         }
//       }
//     }
//   `);

//   useEffect(() => {
//     Aos.init({ duration: 1000, offset: -60 });
//   }, []);

//   if (data.allContentfulFlexBoxStyle.edges.length === 0) {
//     return null; // Handle the case when the desired node is not found
//   }

//   return (
//     <div className="contentFeature7">
//       {/* <div data-aos="fade-up" className="boardTextContainer7 fade-in">
//         <div className={"textContainer7"}>
//           <p className={"title7"}>
//             {data.allContentfulFlexBoxStyle.edges[0].node.title}
//           </p>
//         </div>
//       </div> */}

//       {/* <div className={"displayBarContainer7"}>
//         <div data-aos="fade-up" className="displayBar7" />
//       </div> */}
//       <div className={"imageContainer7"}>
//         <img
//           // data-aos="fade-up"
//           className={"bigImage"}
//           alt={data.allContentfulFlexBoxStyle.edges[0].node.image.altText}
//           key={data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url}
//           src={data.allContentfulFlexBoxStyle.edges[0].node.image.image.file.url}
//         />
//       </div>
//     </div>
//   );
// }
