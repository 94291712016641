import * as React from "react"

import Layout from "../components/Layout"
import HeroImageHeritage from "../components/HeritagePage/HeroImageHeritage"
import HeritagePageText1 from "../components/HeritagePage/HeritagePageText1"
import HeritagePagePic1 from "../components/HeritagePage/HeritagePagePic1"
import HeritagePageText2 from "../components/HeritagePage/HeritagePageText2"
import HeritagePagePic2 from "../components/HeritagePage/HeritagePagePic2"
import HeritagePageText3 from "../components/HeritagePage/HeritagePageText3"
import HeritagePagePic3 from "../components/HeritagePage/HeritagePagePic3"
import HeritagePageText4 from "../components/HeritagePage/HeritagePageText4"

function HeritagePage({ data }) {
  return (
    <Layout>
      <HeroImageHeritage />
      <HeritagePageText1 />
      <HeritagePagePic1 />
      <HeritagePageText2 />
      <HeritagePagePic2 />
      <HeritagePageText3 />
      <HeritagePagePic3 />
      <HeritagePageText4 />
    </Layout>
  )
}

export default HeritagePage