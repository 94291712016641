import React, { useEffect } from "react"
import "../scss/HeritagePageText1.scss"
import { graphql, useStaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Aos from "aos"
import "aos/dist/aos.css"
import arrow from './arrow_down.png';
import { Helmet } from "react-helmet";

export default function HeritagePageText1() {
  const data = useStaticQuery(graphql`
  query MyQuery2100 {
    text: allContentfulTextBlock(filter: { id: { eq: "8fa6015b-6f54-5961-9851-87f0217b19b0" } }) {
      edges {
        node {
          bodyText {
            raw
          }
          id
          title
        }
      }
    }
    timelineimage: allContentfulImageBlock(filter: { id: { eq: "9563134f-0c39-5950-9f0e-a71ff4989441" } }) {
      edges {
        node {
          image {
            altText
            image {
              file {
                url
              }
            }
          }
          id
        }
      }
    }
  }
  `)

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  if (data.text.edges.length === 0) {
    return null; // Handle the case when the desired node is not found
  }

  const { bodyText, title } = data.text.edges[0].node;

  const handleArrowClick = () => {
    // Scroll down 300 pixels when the arrow is clicked
    window.scrollBy({
      top: 300,
      behavior: 'smooth', // for smooth scrolling, you can change to 'auto' for instant scrolling
    });
  };

  return (
    <>
      <Helmet>
        <title>The Fresh Kid - Heritage</title>
        <meta name="description" content="The Fresh Kid - My Heritage - Education and Inspired by Sir Francis Bacon" />
        <meta name="keywords" content="the fresh kid, luxury data analyst" />
        <h1>Heritage Education</h1>

    </Helmet>
    <div className="contentFeatureHeritageText1">
      <div className="contentContainerHeritage1">
          <div className="imageContainerHeritage1">
            <img className="HeritageImage1"
              src={data.timelineimage.edges[0].node.image.image.file.url} 
              alt={data.timelineimage.edges[0].node.image.altText} />
          </div>
          <div className="textContainerHeritage1">
            <p className="titleHeritageText1">{title}</p>
            {bodyText && (
            <div className="bodyHeritageText1">
              {documentToReactComponents(JSON.parse(bodyText.raw))}
            </div>
          )}
          </div>
          

          <div className={'arrowContainerHeritageText1'}>
            <img
              className={'arrowHeritageText1'}
              src={arrow}
              alt="arrow"
              onClick={handleArrowClick} // Attach the click handler here
            />
        </div>
      </div>
    </div>
    </>
  )
}
